var _tracker = _tracker || [];

$(function() {
    var tracker = {
        trackVideoView: function(id) {
            $.ajax({
                    data: {id: id},
                    url: '/track_video.php'
                });
        },
        trackPornstarView: function(id) {
            $.ajax({
                data: {id: id},
                url: '/track_pornstar.php'
            });
        }
    };

    var callArgument = function(i, call) {
        var method = call.shift();
        tracker[method].apply(tracker, call);
    };

    _tracker.push = function() {
        $.each(arguments, callArgument);
    };

    $.each(_tracker, callArgument);
});