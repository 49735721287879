var ajax_button_number_clicks = 1;

$( document ).ready(function() {
    $( "#techpump-paginator-ajax" ).click(function(event) {
        event.preventDefault();

        var url = atob($( "#techpump-paginator-ajax" ).attr("data-ajax-url"));
        var append_to_class = $( "#techpump-paginator-ajax" ).attr("data-ajax-append-to");
        var max_clicks = $( "#techpump-paginator-ajax" ).attr("data-ajax-max-clicks");
        var last_page = $( "#techpump-paginator-ajax" ).attr("data-ajax-last-page");
        var search_query = $( "#techpump-paginator-ajax" ).attr("data-ajax-search-query");
        var filter_character = $( "#techpump-paginator-ajax" ).attr("data-ajax-character-filter");
        var filter_order = $( "#techpump-paginator-ajax" ).attr("data-ajax-order-filter");

        // Si hay parámetro de búsqueda => show_search (usa querystring)
        ajax_button_number_clicks++;

        if (search_query.length > 0) {
            url +="&q=" + search_query;
        }

        if (filter_character.length > 0) {
            url += "&character=" + filter_character;
        }

        if (filter_order.length > 0) {
            url += "&order=" + filter_order;
        }

        url = url.replace("page=0", "page=" + ajax_button_number_clicks);

        // Solo permitimos avanzar si estamos por debajo de max_clicks y last_page
        if (ajax_button_number_clicks <= max_clicks && ajax_button_number_clicks <= last_page) {
            $("#techpump-paginator-ajax").addClass('btn-infinite--loading');
            $.ajax({
                url: url,
                context: document.body
            }).done(function( data ) {
                // Append HTML to container
                $("." + append_to_class).append(data);

                var mobile_first_subid_1 = $("#ajax-paginator-items-" + ajax_button_number_clicks).attr("data-mobile-list-subid_1");
                var mobile_first_subid_2 = $("#ajax-paginator-items-" + ajax_button_number_clicks).attr("data-mobile-list-subid_2");
                var mobile_first_subid_3 = $("#ajax-paginator-items-" + ajax_button_number_clicks).attr("data-mobile-list-subid_3");

                var mobile_first_subid_1_paged = mobile_first_subid_1 + "_" + ajax_button_number_clicks;
                var mobile_first_subid_2_paged = mobile_first_subid_2 + "_" + ajax_button_number_clicks;
                var mobile_first_subid_3_paged = mobile_first_subid_3 + "_" + ajax_button_number_clicks;

                // Add loading effect
                $("#techpump-paginator-ajax").removeClass('btn-infinite--loading');

                // Remove button
                if (ajax_button_number_clicks+1 >= max_clicks || ajax_button_number_clicks >= last_page) {
                    $("#techpump-paginator-ajax").hide();
                }
            });
        } else {
            console.log("Paginator AJAX limit reached! (max_clicks: " + max_clicks + ", last_page: " + last_page + ")");
        }

    });
});

var isMobile = {
    Android: function() {
        return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function() {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function() {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function() {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function() {
        return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
    },
    any: function() {
        return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
    }
};

function UpdateThumbnailsAjax() {
    var maxIndex = 10;
    var intervalTime = 750;
    var className = 'thumbs-changer';

    var addEventListener = function(element, event, callback) {
        if (element.addEventListener) {
            element.addEventListener(event, callback);
        } else {
            element.attachEvent('on'+event, callback);
        }
    };

    var removeEventListener = function(element, event, callback) {
        if (element.removeEventListener) {
            element.removeEventListener(event, callback);
        } else {
            element.detachEvent('on'+event, callback);
        }
    };

    var bindThumbsChanging = function(element) {
        var baseImage;

        if (element.dataset && element.dataset.src) {
            baseImage = element.dataset.src;
        } else {
            baseImage = element.src;
        }


        var getImage = function(index) {
            return baseImage.replace(/\d+.jpg/, index + '.jpg');
        };

        var setImage = function(index) {
            element.src = getImage(index);
        };

        var resetImage = function() {
            element.src = baseImage;
        };

        addEventListener(element, 'mouseover', function() {
            var currentIndex = 1;

            var interval = setInterval(function() {
                currentIndex++;

                if (currentIndex > maxIndex) {
                    currentIndex = 1;
                }

                setImage(currentIndex);
            }, intervalTime);

            setImage(1);

            var mouseOutFunction = function() {
                clearInterval(interval);
                resetImage();
                removeEventListener(element, 'mouseout', mouseOutFunction);
            };

            addEventListener(element, 'mouseout', mouseOutFunction);
        });
    };

    var thumbs;

    if (typeof document.getElementsByClassName == 'function') {
        thumbs = document.getElementsByClassName(className);
    } else if (typeof document.querySelectorAll == 'function') {
        thumbs = document.querySelectorAll('.'+className);
    } else {
        thumbs = [];
        var elem = document.getElementsByTagName('*');
        for (var i = 0; i < elem.length; i++) {
            if((' '+elem[i].className+' ').indexOf(' '+className+' ') > -1) {
                thumbs.push(elem[i]);
            }
        }
    }

    for (var i = 0, end = thumbs.length; i < end; i++) {
        bindThumbsChanging(thumbs[i]);
    }
};

