
$(window ).on("load", function() {
    $('html').addClass('has-transitions');
});

$(document).ready(function() {
    // ================================================

    // Mobile pagination behavior

    // ================================================


    var paginationNextBtn = $('.pagination_item--next .btn-pagination');
    var paginationBtnGutter = 4;
    var paginationContainer = $('.pagination');

    function mobilePagination() {

        var nextBtnWidth = paginationNextBtn.width();
        var paginationRightPadding = nextBtnWidth + paginationBtnGutter;

        if (paginationContainer.prop('scrollWidth') > paginationContainer.width()) {
            paginationContainer.css('padding-right', paginationRightPadding).addClass('pagination--scrollable');
            paginationNextBtn.addClass('btn-pagination--sticky');
        } else {
            paginationContainer.css('padding-right', 0).removeClass('pagination--scrollable');
            paginationNextBtn.removeClass('btn-pagination--sticky');
        }
    }

    mobilePagination();

    $(window).on('resize', function(e) {
        mobilePagination();
    });

});